<template>
	<div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item>提示付款管理</el-breadcrumb-item>
			<el-breadcrumb-item>提示付款申请列表</el-breadcrumb-item>
		</el-breadcrumb>
    <el-alert title="温馨提示：票据到期当日，票交所自动发起提示付款申请" type="warning" :closable="false"></el-alert>
		<el-card>
			<!--搜索-->
			<el-form ref="searchFormRef"
			         :model="searchForm"
			         label-width="90px"
							 class="search-form">
				<el-row :gutter="20">
					<el-col :span="6">
						<el-form-item label="票据包号：" prop="packageNo">
							<el-input v-model="searchForm.packageNo"></el-input>
						</el-form-item>
					</el-col>
          <el-col :span="6">
						<el-form-item label="承兑人：" prop="acceptor">
							<el-input v-model="searchForm.acceptor"></el-input>
						</el-form-item>
					</el-col>
          <el-col :span="12">
						<el-form-item label="到期日期：" class="special-form-item">
							<el-date-picker
											v-model="searchForm.expireDateStart"
											type="date"
											format="yyyy-MM-dd"
											value-format="yyyy-MM-dd"
											placeholder="选择日期">
							</el-date-picker>
							<span class="data-gap">至</span>
							<el-date-picker
											v-model="searchForm.expireDateEnd"
											type="date"
											format="yyyy-MM-dd"
											value-format="yyyy-MM-dd"
											placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-form-item class="btn-form-item">
						<el-button type="primary" @click="getTableList()">查询</el-button>
						<el-button @click="handleReset">重置</el-button>
					</el-form-item>
				</el-row>
			</el-form>
			<!--列表-->
			<el-table :data="tableList" border>
				<el-table-column prop="packageNo" label="票据包号" min-width="150px"></el-table-column>
				<el-table-column prop="packageSum" label="票据包金额（元）" min-width="150px"></el-table-column>
				<el-table-column prop="acceptor" label="承兑人" min-width="120px"></el-table-column>
				<el-table-column prop="maker" label="出票人" min-width="120px"></el-table-column>
				<el-table-column prop="applyDate" label="出票日期" min-width="120px"></el-table-column>
				<el-table-column prop="expireDate" label="票据到期日" min-width="120px"></el-table-column>
				<el-table-column prop="ticketRange" label="子票区间" min-width="130px"></el-table-column>
				<el-table-column prop="ticketStatus" label="票据状态" min-width="100px"></el-table-column>
				<el-table-column prop="ticketType" label="票据种类" min-width="100px"></el-table-column>
				<el-table-column label="操作" fixed="right" min-width="320px">
					<template v-slot="scope">
						<el-button type="primary" size="mini" @click="handleFaceView(scope.row)">
							票面预览
						</el-button>
						<el-button type="primary" size="mini" @click="handleFaceDownload(scope.row)">
							票面下载
						</el-button>
						<el-button type="primary" size="mini" @click="handleTableBtn(scope.row)"> 提示付款申请</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--分页区域 -->
			<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page="searchForm.pageNum"
							:page-sizes="[1, 2, 5, 10]"
							:page-size="searchForm.pageSize"
							layout="total, sizes, prev, pager, next, jumper"
							:total="total"
              class="mt20">
			</el-pagination>
		</el-card>
	</div>
</template>

<script>
  export default {
    name: "PaymentApplyList",
		mounted(){
    	// 获取列表数据
      this.getTableList()
	  },
    data(){
      return {
        // 搜索表单
        searchForm: {
          packageNo:'', // 票据包号
          acceptor: '', // 承兑人
          expireDateStart:'', // 到期日期 起始日期
          expireDateEnd: '', // 到期日期 结束日期
          pageNum: 1,
          pageSize: 10
        },
        total: 0, // 总条数
        tableList: [] // 列表数据
      }
    },
    methods: {
			// 获取列表数据
			async getTableList(){
				const {data: res} = await this.$auth.get('/cpiaoju-ticket/fk/mange/apply/list', {params: this.searchForm});
				if(res.code !== 200) return this.$message.error(res.msg)
				this.total = res.data.total
				this.tableList = res.data.records;
			},
			// 提示付款申请
			handleTableBtn(row){
				this.$router.push({
					name: 'paymentApplyDetail',
					query: {
						ticketId: row.ticketId
					}
				})
			},
      // 票面预览
			async handleFaceView(row){
				let params = {
					paperBagNo: row.packageNo,
					section: row.ticketRange
				}
				const res = await this.$auth.post('/cpiaoju-ticket/common/ticketFaceView', this.$qs.stringify(params), {responseType: 'blob'});
				console.log(res)
				if (res.data.type === "application/pdf"){
					const blob = res.data;
					let link = document.createElement('a');
					link.setAttribute('target', '_blank');
					link.href = window.URL.createObjectURL(blob);
					link.click()
					URL.revokeObjectURL(link.href);
				}else{
					return this.$message.error('暂无票面信息')
				}
			},
			//票面下载
			async handleFaceDownload(row){
				let params = {
					paperBagNo: row.packageNo,
					section: row.ticketRange
				}
				const res = await this.$auth.post('/cpiaoju-ticket/common/ticketFaceDownload', this.$qs.stringify(params), {responseType: 'blob'});
				console.log(res)
				if (res.headers['content-disposition']){
					const blob = res.data;
					const fileName = window.decodeURI(res.headers['content-disposition'].split(';')[1].split('=')[1]); //这是下载的关键
					let link = document.createElement('a');
					let url = URL.createObjectURL(blob);
					link.href = url;
					link.download = fileName
					console.log(link)
					link.click()
					URL.revokeObjectURL(url)
				}else{
					return this.$message.error('暂无票面信息')
				}
			},
      // 重置
      handleReset(){
        this.searchForm.packageNo = ''
        this.searchForm.acceptor = ''
        this.searchForm.expireDateStart = ''
        this.searchForm.expireDateEnd = ''
				// 获取列表数据
				this.getTableList()
      },
      // 监听pagesize改变
      handleSizeChange(newSize){
        this.searchForm.pageSize = newSize;
				// 获取列表数据
        this.getTableList()
      },
      // 监听pagenum改变
      handleCurrentChange(newPage){
        this.searchForm.pageNum = newPage;
				// 获取列表数据
        this.getTableList()
      },
    }
  }
</script>

<style lang="less" scoped>
	.search-form{
		.special-form-item{
			/deep/ .el-form-item__content{
				display: flex;
			}
		}
    .btn-form-item{
			text-align: center;
			.el-button{
				width: 160px;
			}
		}
	}
  .el-alert{
    margin-top: 20px;
  }
</style>